import './App.css';
import {Routes, Route} from "react-router-dom";

/* Pages */
import Contact from './Pages/Contact/Contact';
import DemandeDeDevis from './Pages/DemandeDeDevis/DemandeDeDevis';
import Home from './Pages/Home/Home';
import AvisClient from './Pages/AvisClients/AvisClients';
import Navigation from './Components/Navigation/Navigation';
import Services from './Pages/Services/Services';
import MentionsLégales from './Pages/MentionsLégales/MentionsLégales';

function App() {
  return (
    <div className="App">
      <Navigation />
      <br/>
      <Routes>
        <Route path="/Contact" element={<Contact />}/>
        <Route path="/DemandeDeDevis" element={<DemandeDeDevis />}/>
        <Route path="/" element={<Home />}/>
        <Route path="/AvisClient" element={<AvisClient />}/>
        <Route path="/Services" element={<Services />}/>
        <Route path="/MentionsLégales" element={<MentionsLégales />}/>
      </Routes>
    </div>
  );
}

export default App;
