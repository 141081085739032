import './Card.css'
import './popup.css'

import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, price) {
    return { name, price};
}

const rows_electricite = [
    createData('Pose de lampes et luminaires',  "30 à 150 € / h"),
    createData('Changement d\'ampoule',  "20 € / h"),
    createData('Remplacer une prise éléctrique',  "30 à 70 € / h"),
    createData('Remplacer un interrupteur simple', "30 à 70 € / h"),
    createData('Remplacer un interrupteur va-et-vient', "40 à 70 € / h"),
    createData('Fixation d\'un radiateur éléctrique',  "30 à 80 € / h"),
];
const rows_plomberie = [
    createData('Remplacer un robinet',  "50 à 150 € / h"),
    createData('Purge des radiateurs',  "40 à 80 € / h"),
    createData('Faire / refaire les joints d\'une salle de bain',  "40 à 100 € / h"),
    createData('Changer l\'abattant des W.C',  "20 à 50 € / h"),
    createData('Remplacement kit chasse d\'eau W.C',  "50 à 150 € / h"),
];
const rows_jardin = [
    createData('Tondre la pelouse',  "25 à 45 € / h"),
    createData('Débroussaillage',  "35 à 50 € / h"),
    createData('Désherbage manuel', "35 à 50 € / h"),
    createData('Désherbage thermique', "40 à 50 € / h"),
    createData('Taille de haie',  "25 à 50 € / h "),
    createData('Ramassage de feuilles mortes',  "25 à 50 € / h"),
    createData('Déneiger une allée',  "25 à 50 € / h"),
];
const rows_autres = [
    createData('Montage / assemblage de meubles', "25 à 40 € / h"),
    createData('Démontage de meubles', "25 à 40 € / h "),
    createData('Déplacer un meuble', "25 à 100 € / U"),
    createData('Fixation d\'étagères', "25 à 80 € / h"),
    createData('Détapissage', "9 à 13 € / m²"),
    createData('Boucher un trou', "30 à 80 € / h"),
    createData('Fixation de détecteur de fumée', "30 à 350 € / U"),
    createData('Dépannage sérrure boîte au lettres', "90 à 150 € / U"),
];

export default function Services() {

    return(
        <>
        <div className='services'>
            <div className='services-titre' id='Services'>
                <h1 >Mes services</h1>
            </div>
        </div>

        <div className='row1-container'>
            <div className='box box-down cyan ' >
                <h2>Electricité</h2>   
                <Popup className='popup-content-electricite' trigger={<button className='card-btn'> En savoir plus</button>} position="right center">
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell className=' name-popup-cyan'>Désignation</TableCell>
                            <TableCell className=' name-popup-cyan' align="right">Tarif moyen à titre indicatif&nbsp;(TTC)</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows_electricite.map((rows_electricite) => (
                            <TableRow
                            key={rows_electricite.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {rows_electricite.name}
                            </TableCell>
                            <TableCell align="right">{rows_electricite.price}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Popup>
                <img width="64" height="64" src="https://img.icons8.com/wired/64/0097b2/plug.png" alt="plug" className='vignets-card'/>
            </div>
            
            <div className='box red'>
                <h2>Plomberie</h2>
                <Popup className='popup-content-plomberie' trigger={<button className='card-btn'> En savoir plus</button>} position="right center">
                <TableContainer component={Paper}>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell className='name-popup-red'>Désignation</TableCell>
                            <TableCell className=' name-popup-red' align="right">Tarif moyen à titre indicatif&nbsp;(TTC)</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows_plomberie.map((rows_plomberie) => (
                            <TableRow
                            key={rows_plomberie.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {rows_plomberie.name}
                            </TableCell>
                            <TableCell align="right">{rows_plomberie.price}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Popup>
                <img alt='.' className='vignets-card' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACC0lEQVR4nO2ZPWsUURSGjzZ+FLGyEG31t2wRdd43TCEWwdJUgv2ujRaC/0NcLDSFZVALPwoRImhlH8wKGhfmnCJXrruQgLOTOyETz5V54MAWszAvzzn3XBgx4IuRoU0p+Vq8YcDn1kGAV+IRaxlEvGJ9EGdYb8QZ1hvJ3EgQOSEeUWDSaiGurNxIDaPkewXeHtd2/9TWymHnKuHWMInvo+S6AWtT4FJykD9/chLE/r7X7Ro5roriSoqRNa9BbM+UGTlsbOmob57cbRDbq3FYXj672Ao5ziRIMPLpQjOxB+f6cggSYps1WRnmEkQBq8jLtUGiLgMe5xDEZvVkoZU4SF3MSxdBlNydluXFxWGiGXJ4lDPTkZFg5G05iNiDUd9RHM2dBQGeSypRX0xu5DMjN9vezTo2sinHgZLvlHyT+nwFXGs1J8BEPBJm83ko2+6wPogzrDfiDOuNOMNyNxJmO+Rj9t9rwmh00oAP/833mjoq8mosyZkwGJwy4Gus+Ftyxci7+67tdyRHQlmeU3J732B/C4PBkuSGkvdrhvue5EQALig5rTluf4ayPC+5oMCDhmN3JDkQyvJMnIeGJbgVVldPi3cUuJWwCG+Kd5R8kXA1WRfvKPk9Ici2eEeBnYTW+iHeUeBlQpAN8U5VFDwoSFUU1yUHFHjUYOPhv36/VlQAYgsp8GteG3UmfgO/bwDC5G8MpwAAAABJRU5ErkJggg=="/>            </div>

            <div className='box box-down green'>
                <h2>Jardin</h2>
                <Popup className='popup-content-jardin' trigger={<button className='card-btn'> En savoir plus</button>} position="right center">
                <TableContainer component={Paper}>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell className='name-popup-green'>Désignation</TableCell>
                            <TableCell className=' name-popup-green' align="right">Tarif moyen à titre indicatif&nbsp;(TTC)</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows_jardin.map((rows_jardin) => (
                            <TableRow
                            key={rows_jardin.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {rows_jardin.name}
                            </TableCell> 
                            <TableCell align="right">{rows_jardin.price}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Popup>
                <img width="50" height="50" className='vignets-card' src="https://img.icons8.com/quill/50/40C057/yard-work.png" alt="yard-work"/>

            </div>

            <div className='box orange'>
                    <h2>Petits bricolages</h2>
                    <Popup className='popup-content-autres' trigger={<button className='card-btn'> En savoir plus</button>} position="right center">
                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell className=' name-popup-orange'>Désignation</TableCell>
                                <TableCell className=' name-popup-orange' align="right">Tarif moyen à titre indicatif&nbsp;(TTC)</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows_autres.map((rows_autres) => (
                                <TableRow
                                key={rows_autres.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {rows_autres.name}
                                </TableCell>
                                <TableCell align="right">{rows_autres.price}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Popup>
                    <img width="64" height="64" className='vignets-card' src="https://img.icons8.com/glyph-neue/64/FD7E14/hammer.png" alt="hammer"/>
                </div>
            
        </div>

        </>
    )
    
}
