import './CContact.css';
import emailjs from '@emailjs/browser';
import React, { useState } from 'react';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function CContact() {
    const [formData, setFormData] = useState({
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        obj: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_xkvyzta",
                "template_qtw34nr",
                e.target,
                "ndYFS9Qsd5_EI6_3i"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    console.log("message sent");
                    document.getElementById("alert-box-c").style.display = "flex";
                    // Réinitialiser le formulaire après l'envoi
                    setFormData({
                        lastname: '',
                        firstname: '',
                        email: '',
                        phone: '',
                        obj: '',
                        message: '',
                    });
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <>
            <div className='services'>
                <div className='services-titre' id='Contact'>
                    <h1>Contact</h1>
                </div>
            </div>
            <div className='container'>
                <div className="text1">
                    <p>
                        Vous avec une question ? Une demande ? <br/> 
                        N'hésitez pas à me contacter sur cette page.<br/>
                        Je m'engage à répondre à vos questions 
                        <br/>dans les meilleurs délais.
                        <br/><br/>
                        <b>Merci de votre confiance !</b>
                    </p>
                </div>
            <div className='ccontact-container'>
                <form className='contactForm' onSubmit={sendEmail} name='form' id='form'>
                    <label>Votre nom <b className='required'>*</b></label>
                    <input
                        type='text'
                        name='lastname'
                        value={formData.lastname}
                        onChange={handleChange}
                        required
                        minLength="3"
                    />

                    <label>Votre prénom <b className='required'>*</b></label>
                    <input
                        type='text'
                        name='firstname'
                        value={formData.firstname}
                        onChange={handleChange}
                        required
                        minLength="3"
                    />

                    <label>Votre email <b className='required'>*</b></label>
                    <input
                        type='email'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        required
                        minLength="5"
                    />

                    <label>Votre téléphone <b className='required'>*</b></label>
                    <input
                        type='tel'
                        name='phone'
                        value={formData.phone}
                        onChange={handleChange}
                        required
                        minLength="9"
                        maxLength="10"
                    />

                    <label>Objet <b className='required'>*</b></label>
                    <input
                        type='text'
                        name='obj'
                        value={formData.obj}
                        onChange={handleChange}
                        required
                        minLength="5"
                    />

                    <label>Votre message <b className='required'>*</b></label>
                    <textarea
                        name='message'
                        value={formData.message}
                        onChange={handleChange}
                        minLength="5"
                        required
                    ></textarea>

                    <br />

                    <input type='submit' name='mailform' value='ENVOYER' />
                    <div id='alert-box-c'>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="success">Message envoyé avec succès !</Alert>
                        </Stack>
                    </div>
                </form>
                </div>
            </div>
        </>
    );
}
