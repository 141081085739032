import './CDevis.css';
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function CDevis() {
    const [formData, setFormData] = useState({
        lastname: '',
        firstname: '',
        address: '',
        email: '',
        phone: '',
        job: '#', // Mettez la valeur par défaut correspondante ici
        commentaire: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_sm5q9qi",
                "template_2j7o29t",
                e.target,
                "ndYFS9Qsd5_EI6_3i"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    console.log("message sent");
                    document.getElementById("alert-box-d").style.display = "flex";
                    // Réinitialiser le formulaire après l'envoi
                    setFormData({
                        lastname: '',
                        firstname: '',
                        address: '',
                        email: '',
                        phone: '',
                        job: '', // Réinitialisez à la valeur par défaut
                        commentaire: '',
                    });
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <>
            <div className='services'>
                <div className='services-titre' id='DemandeDeDevis'>
                    <h1 >Demande de devis gratuit</h1>
                </div>
            </div>
            <div className='container'>
                <div className="text1">
                    <p>Vous avez un projet de travaux ?<br/>
                    Vous souhaitez obtenir un devis <br/>
                    gratuit et sans engagement ? <br/>
                    Sur cette page, vous pouvez <br/>
                    m'envoyer une demande de devis en<br/>
                    quelques clics.<br/>
                    Votre demande sera traitée dans les<br/> 
                    meilleurs délais. Je vous<br/> 
                    contacterai au plus vite pour vous <br/>
                    fournir un devis gratuit et <br/>
                    personnalisé.
                    <br/><br/>
                    <b>Merci de votre confiance !</b>
                    </p>
                </div>
            <div className='cdevis-container'>
                <form className='devisForm' onSubmit={sendEmail} name='devisForm'>
                    <label>Votre nom <b className='required'>*</b></label>
                    <input
                        type='text'
                        name='lastname'
                        value={formData.lastname}
                        onChange={handleChange}
                        required
                        minLength="3"
                    />

                    <label>Votre prénom <b className='required'>*</b></label>
                    <input
                        type='text'
                        name='firstname'
                        value={formData.firstname}
                        onChange={handleChange}
                        required
                        minLength="3"
                    />

                    <label>Votre adresse complète <b className='required'>*</b></label>
                    <input
                        type='text'
                        name='address'
                        value={formData.address}
                        onChange={handleChange}
                        required
                        minLength="3"
                    />

                    <label>Votre email <b className='required'>*</b></label>
                    <input
                        type='email'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        required
                        minLength="5"
                    />

                    <label>Votre téléphone <b className='required'>*</b></label>
                    <input
                        type='tel'
                        name='phone'
                        value={formData.phone}
                        onChange={handleChange}
                        required
                        minLength="9"
                        maxLength="10"
                    />

                    <label>Type de services <b className='required'>*</b></label>
                    <select
                        name="job"
                        value={formData.job}
                        onChange={handleChange}
                        required
                    >
                        <option value="#">-- Selectionner votre service --</option>
                        <option value="electricite">Electricité</option>
                        <option value="plomberie">Plomberie</option>
                        <option value="jardinage">Jardin</option>
                        <option value="petits bricolages">Petits bricolages</option>
                        <option value="autre">Autre</option>
                    </select>

                    <label>Description des travaux <b className='required'>*</b></label>
                    <textarea
                        name='commentaire'
                        value={formData.commentaire}
                        onChange={handleChange}
                        required
                        minLength="5"
                    ></textarea>

                    <p className='details'>Merci de bien vouloir détailler le projet le plus précisément possible.</p>
                    <br />

                    <input type='submit' name='mailform' value='ENVOYER LA DEMANDE' />
                    <div id='alert-box-d'>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="success">Demande envoyé avec succès !</Alert>
                        </Stack>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
}
