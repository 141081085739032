import CContact from '../../Components/Contact/CContact';
import Footer from '../../Components/Footer/Footer';

import './Contact.css';

export default function Contact() {

    

    return(
        <>
        <div className='main'>
            <CContact />
            <br/><br/>
            <Footer />
        </div>
        </>
    )
}