import CDevis from '../../Components/Devis/CDevis';
import Footer from '../../Components/Footer/Footer';
import './DemandeDeDevis.css';

export default function DemandeDeDevis() {
    return(
        <>
        <div className='main'>
            <CDevis />
            <br/><br/>
            <Footer />
        </div>
        </>
    )
}