import Footer from '../../Components/Footer/Footer'
import Card from '../../Components/Card/Card'
import './Services.css'

export default function Services() {
    return(
        <>
            <Card />
            <br/><br/>
            <Footer />
        </>
    )
}