import Footer from "../../Components/Footer/Footer";

export default function AvisClients() {
    return(
        <>
        <div className='main'>
            <Footer />
        </div>
        </>
    )
}